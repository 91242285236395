







































import { Component, Prop, Vue } from "vue-property-decorator";
import { TLoginForm, TLoginRules } from "./types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "../commons/inputsAndControls/TextField.vue";
import Logo from "../commons/Logo.vue";
import Button from "../commons/inputsAndControls/Button.vue";
import { Routes } from "../../enums/Routes";

@Component({
  components: {
    Logo,
    ValidationObserver,
    ValidationProvider,
    TextField,
    Button,
  },
})
export default class LoginForm extends Vue {
  Routes = Routes;

  @Prop({ required: true, type: Function })
  private onSubmit!: (payload: TLoginForm) => Promise<boolean>;

  form: TLoginForm = {
    email: "",
    password: "",
  };

  rules: TLoginRules = {
    email: {
      required: true,
      email: true,
    },
    password: {
      required: true,
    },
  };

  async submit(): Promise<void> {
    const result = await this.onSubmit(this.form);
    if (result) {
      if (this.$route.query?.redirect) {
        this.$router.push(this.$route.query.redirect as string);
      } else {
        this.$router.push("/");
      }
    }
  }
}
