










import { Component, Vue } from "vue-property-decorator";
import LoginLayout from "../components/commons/layouts/LoginLayout.vue";
import LoginHandler from "../components/auth/LoginHandler.vue";

@Component({
  components: {
    LoginLayout,
    LoginHandler,
  },
})
export default class LoginPage extends Vue {}
